<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/fourthIhram">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <router-link to="/sixthIhram">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <div class="info">
          <p class="text">6. Находящимся в ихраме запрещено сва- таться или жениться. Также запрещены при- косновения к жене с вожделением и половая близость.</p>
          <i class="text text_green">На что указывает хадис Усмана (да будет доволен им Аллах!), что пророк сказал: </i>
          <i class="text text_green">«Находящийся в ихраме не женится, не женит и не сватается»</i>
          <p class="text_orange center">(Муслим)</p>
          <p class="text">7. Женщине в ихраме запрещено надевать перчатки, закрывать лицо паранджой или никабом. но она может прикрываться чем-нибудь, к примеру краем платка, когда окажется рядом с посторонними мужчинами.</p>
          <p class="text">8. Мужчине, находящемуся в ихраме, зап- рещено покрывать голову тюбетейкой, чалмой, накидкой и т.д. Если же это произошло по забывчивости или по нез- нанию, то паломник обязан, как только вспомнит или узнает об ошибке, снять голов- ной убор. искупительных действий от него не требуется.</p>
          <p class="text">9. Находящемуся в ихраме запрещено на- девать сшитую одежду, например: рубашку, брюки, туфли, нижнее белье и т.д. Если же у человека нет изара (куска материи, обво- рачиваемого вокруг нижней части тела), то ему разрешается надеть брюки. А тому, кто не нашел сандалий, можно носить туфли.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  & .text:not(:first-child) {
    padding-top: 16px;
  }
}
.center {
  text-align: center;
  padding-top: 16px;
  font-style: italic;
}
</style>
