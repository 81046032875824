<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/fifthIhram">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <div></div>
      </div>

      <div class="content">
        <h2 class="title_pre">ВО ВРЕМЯ ИХРАМА РАЗРЕШАЮТСЯ СЛЕДУЮЩИЕ ВЕЩИ</h2>

        <div class="info">
          <div class="info__block">
            <img src="">
            <p class="text">1. надевать часы</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">2. использовать слуховые аппараты или наушники</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">3. носить кольца</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">4. надевать сандалии</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">5. носить очки.</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">6. Подпоясываться ремнем.</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">7. укрываться под тенью зонта</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">8. укрываться под крышей машины</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">9. носить поклажу на голове</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">10. носить постель на голов</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">11. Перевязывать бинтом рану, менять одежду
              ихрама и стирать ее</p>
          </div>
          <div class="info__block">
            <img src="">
            <p class="text">12. мыть голову и принимать душ, если при этом случайно выпадут волосы, то в этом нет ничего греховного</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  padding-block: 24px;
  &__block {
    display: flex;
    &:not(:first-child) {
      padding-top: 16px;
    }
  }
}
</style>
