<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <div></div>
        <h2 class="title">Таваф</h2>
        <router-link to="/secondTawaf">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <div class="steps"></div>
        <div class="info">
          <p class="text">1. Таваф вокруг Каабы начинается с того угла, где находится черный камень и заканчивается там же.</p>
          <p class="text">2. Обход совершается против часовой стрел- ки, таким образом, чтобы Кааба всегда оставалась по левую сторону от совершаю- щего таваф.</p>
          <p class="text">3. Таваф необходимо совершать за хиджром.</p>
          <p class="text">4. Таваф состоит из 7 кругов.</p>
          <p class="text">5. Таваф разрешается совершать только при наличии малого омовения.</p>
          <p class="text">6. После тавафа желательно прочитать 2 ракаата намаза за макамом Ибрахима или внутри хиджра.</p>
          <p class="text">7. Таваф в мечети аль-Харам разрешается выполнять на любом этаже.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.steps {
  margin: 0 auto;
  background: url("../../assets/img/tavaf.svg") center/cover no-repeat;
  width: 100%;
  max-width: 307px;
  height: 185px;
}
.info {
  padding-block: 30px;
  .text:not(:first-child) {
    padding-top: 15px;
  }
}
</style>
