import { createApp } from 'vue'
import App from './App.vue'
import "@/assets/scss/app.scss"
import router from '@/router/router.js'


const app = createApp(App)

app.use(router)

app.mount('#app')
