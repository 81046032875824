<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/sai">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Са’й</h2>
        <router-link to="/thirdSai">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <div class="info">
          <p class="text text_arab">لا إله إلا الله، وحده لا شريك له، له الملك، وله الحمد، يحيي ويميت وهو على كل شيء قدير، لا إله إلا الله وحده أنجز وعده، ونصر عبده، وهزم الأحزاب وحده</p>
          <p class="text text-center">«Ля иляха илляллаху уахдаху ля шарикалях. Ляхуль мульку уа ляхуль хамду уа хууа ъаля кули шай ин кадир. Ля иляха илляллаху уахдаху анджаза уаъдаху уа насора ъабдаху уа хазамаль ахзаба уахдаху».</p>

          <div class="info-text">
            <p class="text">Между этими словами паломник просит Всевышнего, о чем пожелает, и если мольба будет короче приведенной формы, это разрешается. Руки поднимаются только при совершении ду’а, при словах же «Аллаху акбар» поднимать их не следует. Частой ошибкой паломников является указывание обеими руками в сторону каабы.</p>
            <p class="text">Далее паломник спускается с Сафы и движется в направлении Марвы обычным шагом, в этот момент человек делает ду’а для себя, семьи и других мусульман. Мужчины, дойдя до зеленых фонарей, переходят на бег до следующих зеленых фонарей, после чего продолжают идти обычным шагом.</p>
            <p class="text"><span class="text_orange">2. Марва.</span> Достигнув Марвы, паломник поворачивается к каабе и произносит те же слова, что и на сафе, за исключением аята. Делает ду’а, о чем пожелает и направляетсяк Сафе. Всякий раз, когда мужчины подходят к</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &-center{
      text-align: center;
    }
  }
  &-text {
    padding-top: 24px;
  }
}
</style>
