<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/thirdIhram">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <router-link to="/fifthIhram">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <h2 class="title_pre">В ИХРАМЕ ЗАПРЕЩАЕТСЯ</h2>

        <div class="info">
          <p class="text">1. Удаление своих волос или ногтей. Нет ниче- го страшного, если человек по забывчивости или по незнанию совершил это, или же если волосы выпали сами по себе, не преднаме- ренно.</p>
          <p class="text">2. Находящемуся в ихраме запрещено поль- зоваться каким-либо благовонием, наноси- мыми на тело или на одежду. Нет ничего пре- досудительного в том, что остается от запаха благовония, нанесенного на тело перед вхож- дением в состояние ихрама; если же благово- ние попало на одежду, то его следует смыть.</p>
          <p class="text">3. Мусульманину на территории аль-Харама запрещено охотиться на животных суши или помогать в охоте, это относится ко всем: мужчинам и женщинам, находящимся и не находящимся в состоянии ихрама.</p>
          <p class="text">4. Мусульманину на территории аль-Харама запрещено ломать деревья и рвать дикорос- тущую зеленую траву. Это относится ко всем: мужчинам и женщинам, находящимся и не находящимся в состоянии ихрама.</p>
          <p class="text">5. Мужчинам и женщинам, находящимся и не находя-щимся в состоянии ихрама, на терри- тории Мекки и Медины запрещено подбирать потерянные деньги, золото, серебро и другие вещи, кроме как для объявления о находке, с намерением отыскать хозяина.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  padding-top: 24px;
  & .text:not(:first-child) {
    padding-top: 16px;
  }
}
</style>
