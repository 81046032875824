<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
.accordion {
  width: 100%;
}
</style>
