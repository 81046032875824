<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/tawaf">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <router-link to="/thirdTawaf">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

     <div class="content">
       <div class="info">
         <p class="text">8. При совершении тавафа необходимо приложить максимум усилий, чтобы не навредить окружающим.</p>

         <p class="text">Как было сказано выше, совершающий умру должен произносить слова тальбии, начиная с миката до тех пор, пока не увидит Каабу. При прибытии паломника в благословенную мекку, желательным для него является совершение полного омовения. Затем он отправляется к Священной мечети, чтобы выполнить обряды умры. Совершение полного омовения перед отправлением в мечеть не обязательно.<br><br>
           Входить в мечеть следует правой ногой, произнося следующие слова:</p>
         <p class="text text_arab">أَعُوذُ بِاللَّهِ العَظِيمِ، وَبِوَجْهِهِ الْكَرِيمِ، وَسُلْطَانِهِ الْقَدِيمِ، مِنَ <br>الشَّيْطَانِ الرَّجِيمِ</p>
         <p class="text center">«А‘узу би-Лляхи ль-‘Азыми ва би-ваджхи-хи ль-карими ва сультани-хи ль-кадими мин аш-шайтани р-раджим»</p>

         <p class="text text_orange center">«Прибегаю к защите Аллаха Великого, Его благородного лика и Его предвечной <br>власти от проклятого шайтана»</p>
         <p class="text text_arab">بِسْمِ اللَّهِ، وَالصَّلَاةُ وَالسَّلَامُ عَلَى رَسُولِ اللَّهِ اللَّهُمَّ <br>افْتَحْ لِي أَبْوَابَ رَحْمَتِكََ</p>
         <p class="text center">«Би-сми Лляхи ва-с-саляту ва-с-саляму ‘аля расули Ллях. Аллахумма фтах ли абваба рахмати-ка»</p>
       </div>
     </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  .center {
    text-align: center;
  }
}

</style>
