<script setup>
import { ref, defineProps } from 'vue';

defineProps({
  title: {
    type: String,
    required: true
  }
});

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="accordion-item">
    <div class="accordion-header" @click="toggle">
      <h3 class="title_pre title_qa">{{ title }}</h3>
      <img :class="{'rotate': isOpen}"  class="arrow" src="@/assets/img/icons/down-arrow.png">
    </div>
    <div v-if="isOpen" class="accordion-content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion {
  &-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  &-header {
    background: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-content {
    padding: 10px;
    background: #fff;
  }
}
.title_qa {
  text-align: left;
  padding-right: 20px;
  text-align: justify;
}
.arrow {
  transition: transform 0.3s ease;
  &.rotate {
    transform: rotate(180deg);
  }
}
</style>
