<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/ihram">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <router-link to="/thirdIhram">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <p class="text">Мужчине следует облачиться в две белые, чистые и не шитые материи, одну из которых обворачивают вокруг нижней части тела, а другую накидывают на плечи. Нижняя материя называется изар, а верхняя рида</p>

        <div class="people">
          <div class="container people__container">
            <img src="@/assets/img/adam1.png">
            <img src="@/assets/img/adam2.png">
            <img src="@/assets/img/adam3.png">
            <img src="@/assets/img/adam4.png">
          </div>
        </div>

        <div class="info">
          <div class="info__block">
            <img src="@/assets/img/icons/1.svg">
            <p class="text">Поставьте<br>
              ноги на<br>
              ширине<br>
              плеч и<br>
              затяните<br>
              края изара</p>
          </div>
          <div class="info__block">
            <img src="@/assets/img/icons/2.svg">
            <p class="text">Затяните<br>
              по туже<br>
              верхние<br>
              края изара<br>
              и скрутите<br>
              их к низу</p>
          </div>
          <div class="info__block">
            <img src="@/assets/img/icons/3.svg">
            <p class="text">Поправьте<br>
              изар таким,<br>
              образом<br>
              чтобы он<br>
              был выше<br>
              пупка и<br>
              ниже колен</p>
          </div>
          <div class="info__block">
            <img src="@/assets/img/icons/4.svg">
            <p class="text">Накиньте<br>
              рида на<br>
              левое<br>
              плечо...</p>
          </div>
        </div>

        <div class="info__text">
          <i class="text_green">Посланник Аллаха сказал: «Нельзя надевать (будучи в ихраме) рубашку, чалму, брюки, любую одежду, покрывающую тело вместе с головой, и обувь, целиком покрывающую ноги. Если же паломник не найдет сандалий, то пусть наденет кожаную обувь, предварительно обрезав ее ниже щиколоток. Не надевайте также одежды, окрашенные шафраном или желтым деревом».</i>
          <p class="text__source">(Аль-Бухари и Муслим)</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.people {
  background-color: #187E8D;
  padding-block: 24px;
  padding-inline: 20px;
  margin-block: 16px;
  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      padding-bottom: 10px;
    }
  }
  &__text {
    padding-top: 16px;
  }
}
</style>
