<script setup>
import { ref, defineProps } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: true
  },
  uniqueId: {
    type: String,
    required: true
  }
})

const isChecked = ref(false)
const isAccordionOpen = ref(false)

const toggleAccordion = () => {
  isAccordionOpen.value = !isAccordionOpen.value
}

const formatItem = (item) => {
  return item.replace(/•/g, '<br>•')
}
</script>

<template>
  <div class="accordion-checkbox">
    <div class="checkbox-section">
      <label :for="`docsCheckbox-${props.uniqueId}`" class="title_pre title_qa">{{ props.title }}</label>
      <div class="checkbox-wrapper">
        <input type="checkbox" :id="`docsCheckbox-${props.uniqueId}`" v-model="isChecked" />
        <span class="custom-checkbox"></span>
      </div>
    </div>
    <div class="view-button" @click="toggleAccordion">
      Смотреть
    </div>
    <div class="content-section" v-if="isAccordionOpen">
      <div v-if="props.description" class="description text" v-html="props.description"></div>
      <ol class="custom-list">
        <li v-for="(item, index) in items" :key="index" class="text_acc">
          <span v-html="formatItem(item)"></span>
        </li>
      </ol>
    </div>
  </div>
</template>

<style scoped>
.accordion-checkbox {
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 10px;
}
.checkbox-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.checkbox-wrapper input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
}
.checkbox-wrapper .custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #4caf50;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox-wrapper input[type="checkbox"]:checked + .custom-checkbox::after {
  content: '✔';
  font-size: 18px;
  color: #4caf50;
}
.view-button {
  color: green;
  cursor: pointer;
  margin-top: 5px;
}
.content-section {
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.description {
  padding-bottom: 10px;
}
.custom-list {
  margin: 0;
}
.title_qa {
  text-align: left;
  padding-right: 2px;
}
.text_acc {
  color: #525D68;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
</style>
