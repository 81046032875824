<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <div></div>
        <h2 class="title">Ихрам</h2>
        <router-link to="/secondIhram">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <h2 class="title_pre">ДЕЙСТВИЯ, СОВЕРШАЕМЫЕ ДО<br>ВХОЖДЕНИЯ В ИХРАМ</h2>
        <div class="info">
          <p class="text">1. Следует подстричь ногти, усы и удалить волосы с подмышечных впадин и лобка.</p>
          <p class="text">2. Совершить полное омовение, если есть возможность для этого.</p>
          <p class="text">3. Мужчина полностью снимает с себя всю (сшитую нитями) одежду и облачается в одеяние ихрама.</p>
          <p class="text">4. Женщина не должна надевать паранджу, никаб (вуаль) и перчатки. Но ей разрешается прикрывать свое лицо краем платка при посторонних мужчинах.</p>
          <p class="text">5. После гусля, по возможности, мужчина умащает свое тело благовониями, но не наносит его на одежды ихрама.</p>
          <p class="text">6. После выполнения вышеперечисленного, мусульма- нин делает намерение на Умру. Это намерение и является вхождением в состояние ихрама. Если наступило время обязательного намаза, то лучше вознамериться войти в состояние ихрама после молитвы. В иное же время после ихрама можно совершить два рака’ата намаза.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  padding-block: 30px;
  .text:not(:first-child) {
    padding-top: 16px;
  }
}
.tex {
  display: flex;
}
</style>
