<script setup>
</script>

<template>
  <footer class="footer">
    <div class="footer__container">
      <img src="@/assets/img/icons/main.svg">
      <img src="@/assets/img/icons/x.svg">
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  background-color: #034752;
  border-radius: 22px 22px 0px 0px;
  padding: 16px 0 35px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  &__container {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
