<script setup>
</script>

<template>
  <section class="background">
   <div class="container">
     <div class="header_block">
       <div></div>
       <h2 class="title">Микаты</h2>
       <router-link to="/secondMikat">
         <img src="@/assets/img/icons/next.svg">
       </router-link>
     </div>

     <div class="content">
       <p class="text"><span class="text__selected">Микаты</span> – это стоянки, на которых паломники обла-
         чаются в ихрам. Пророк определил 5 микатов, на
         которых совершающие хадж или умру, обязаны вступить
         в состояние ихрама.</p>

       <div class="steps"></div>

       <div class="info__container">
         <p class="text"><span class="text__selected">1. Зуль-Хулейфа ذو الحليفة</span> - микат жителей Медины и
           паломников, прибывающих с этого направления.
           Сейчас это место называется «Абйар» и находится в 450
           км от Благосло- венной Мекки.</p>
         <p class="text"><span class="text__selected">2. Аль-Джухфа الجحفة</span> - микат жителей Шама (Сирии,
           Иордании, Палестины, Ливана), Марокко, Египта и
           паломников, прибывающих с этого направления. Находится вблизи горо- да Рабиг, в 183 км от Благословенной
           Мекки.</p>
         <p class="text"><span class="text__selected">3. Кари аль-маназиль قرن المنازل</span> - микат жителей Неджа
           (центральные и восточные районы Саудовской Аравии)
           и паломников, прибывающих с этого направления.
           Сайчас это место называется «ас-Сейлуль кабир» и
           находится в 75 км от Благословенной Мекки.</p>
       </div>

     </div>
   </div>
  </section>
</template>

<style lang="scss" scoped>
.steps {
  margin: 0 auto;
  background: url("../../assets/img/kaaba.svg") center/cover no-repeat;
  width: 100%;
  max-width: 420px;
  height: 190px;
  margin-top: 32px;
}
.info {
  &__container {
    padding-top: 32px;

    & .text:not(:first-child) {
      padding-top: 16px;
    }
  }
}

.left {
  text-align: left;
}
.center {
  text-align: center !important;
}
</style>
