<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/secondIhram">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Ихрам</h2>
        <router-link to="/fourthIhram">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <div class="topic">
          <h2 class="title_pre">НАМЕРЕНИЕ НА ИХРАМ</h2>
          <p class="text text_block text_arab">اللهم لبيك عمرة</p>
          <p class="text">«Аллахумма ляббейка умратан»</p>
        </div>

        <div class="topic_translate">
          <p class="text_orange center">«Вот я перед тобой, (о Аллах),<br> совершаю малый хадж»</p>
        </div>

        <div class="topic text_top">
          <h2 class="title_pre">ТАЛЬБИЯ</h2>
          <p class="text text_block text_arab">لبَّيكَ اللهمَّ لبَّيكَ، لبَّيكَ لا شريكَ لك لبَّيكَ؛ إنَّ<br> الحمدَ والنِّعمةَ لك والمُلْك، لا شريكَ لك</p>
          <p class="text">«Ляббайка-ллахумма ляббайк, ляббайка ля шарика ляка ляббайк, инналь-хамда, ванниъмата ляка вальмулк, ля шарика ляк».</p>
        </div>

        <div class="topic_translate">
          <p class="text_orange center">«Вот я перед Тобой, о Аллах,<br> вот я перед Тобой! Нет у тебя сотоварища, <br>вот я перед тобой! Поистине вся хвала,<br> милость и владычество принадлежат<br> Тебе, нет у Тебя сотоварищей».</p>
        </div>

        <div class="topic text_top">
          <h2 class="title_pre">ВРЕМЯ ТАЛЬБИИ ДЛЯ УМРЫ</h2>
          <p class="text text_block">С момента вхождения в ихрам и<br> до начала тавафа</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.topic {
  display: flex;
  flex-direction: column;
  align-items: center;
  &_translate {
    padding-block: 24px;
  }
}
.text {
  text-align: center;
  &_block {
    padding-block: 24px;
  }
  &_top {
    padding-top: 24px;
  }
}

.center {
  text-align: center;
}
</style>
