<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/secondTawaf">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <router-link to="/fourthTawaf">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

     <div class="content">
       <div class="info">
         <p class="text text_orange text_center">«С именем Аллаха, благословение и мир посланнику Аллаха. О Аллах! Открой для меня врата Твоего милосердия»</p>

         <p class="text">Затем паломник направляется к Каабе и начинает таваф. Для мужчин, совершающих умру или таваф аль-кудум, сунной является обнажать правое плечо. Для этого середина накидки рида ложится под правой подмы- шечной впадиной, а края закидываются на левое плечо.</p>
         <div class="info_left">
           <p class="text">1. Далее совершающий умру приступает к семикратному обходу вокруг каабы. Первый круг начинается с черного камня со словами: <span class="text_orange">«Бисмилляхи, Аллаху акбар».</span>  Достаточно издали указать правой рукой на черный камень со словами <span class="text_orange">«Аллаху акбар».</span></p>
           <p class="text">2. В промежутке между йеменским углом и черным камнем в каждом кругу по сунне следует читать:</p>

           <p class="text text_arab">رَبَّنَا آتِنَا فِي الدُّنْيَا حَسَنَةً وَفِي الآخِرَةِ حَسَنَةً وَقِنَا عَذَابَ النَّارِ</p>
           <p class="text text_center">«Раббана атина фид дуния хасана-тау уа филь
             ахирати хасанатау уа қинә ъазәбәннәр»</p>

           <p class="text text_orange text_center">«Господь наш! Одари нас добром в этом мире и добром в Последней жизни и защити нас от мучений в огне»»</p>
         </div>
       </div>
     </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  &_left {
   margin-left: 10px;
    padding-top: 20px;
  }
  .text {
    &:not(:first-child) {
      padding-top: 25px;
    }
    &_center {
      text-align: center;
    }
  }
}

</style>
