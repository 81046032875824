<script setup>


</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/secondMikat">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Микаты</h2>
        <div></div>
      </div>
      <div class="content">
        <div class="box">
          <p class="text box_import">
            <span><img src="../../assets/img/icons/important.svg"></span>
            Каждый паломник, совершающий хадж или умру, обязан при достижении миката войти в состояние ихрама. если же человек миновал микат и намеренно не вошел в состояние ихрама, то он обязан вернуться и войти в ихрам с пропу- щенного места. в противном же случае, на нем лежит обязанность принести в жертву овцу в мекке и там же раздать мясо бедным.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.box {
  border: 1px solid #DB0E21;
  border-radius: 15px;
  padding: 15px;
  &_import {
  }
}

</style>
