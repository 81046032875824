<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/secondSai">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Са’й</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="info">
          <p class="text">зеленым фонарям, им необходимо ускорять свой шаг до следующих фонарей. На сафе повторяются те же действия. Прохождение расстояния от Сафы до Марвы засчитывается за один круг са’я. В такой вот форме следует совершить семь кругов са’я.</p>
          <p class="text">Паломнику, начавшему са’й пешим ходом, разрешается закончить его на коляске, если он почувствует физическое недомогание или боль, не позволяющую продолжать движение самостоятельно.</p>
          <p class="text">После са’йя совершающий умру сбривает или укорачивает волосы на голове (бритье волос предпочтительней). Волосы должны быть равномерно подстриженными по всей голове.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  .text:not(:first-child) {
    padding-top: 16px;
  }
}
</style>
