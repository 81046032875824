<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/thirdTawaf">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Таваф</h2>
        <div></div>
      </div>

      <div class="content">
        <div class="info">
          <h2 class="title_pre">ПОСЛЕ ЗАВЕРШЕНИЯ ТАВАФА</h2>
          <p class="text">1. Необходимо закрыть правое плечо (мужчи- нам).</p>
          <p class="text">2. Совершить намаз в два рака’ата за <span class="text_orange">Макамом Ибрахима,</span> что является сунной. Если читать намаз за макамом затруд- нительно, то можно совершить его в любом другом месте мечети. В первом рака’ате после аль-фатихи желательно прочитать суру «аль-кяфирун». Во втором рака’ате после аль-фатихи желательно прочитать суру «аль-ихляс». Разрешается, если эти две суры будут заменены на другие.</p>
        </div>
        <div class="info">
          <h2 class="title_pre">СУРА «АЛЬ-КАФИРУН»</h2>
          <p class="text text_center">Бисмилләһир-рахмәәнир-рахиим</p>
          <p class="text text_center">Қул йаә әйюһәл кәәфируун (1) Ләәә әъбуду мәә тәъбу- дун (2) Уә ләә әнтум ъабидуунә мәә әъбуд (3) Уә ләә әнә ъаабидум мәә ъабәдтум (4) Уә ләә әнтум ъаабидуу- нә мәә әъбуд (5) Ләкум диинукум уә лиә диин (6)</p>
        </div>
        <div class="info">
          <h2 class="title_pre">СУРА «АЛЬ-ИХЛАС»</h2>
          <p class="text text_center">Бисмилләһир-рахмәәнир-рахиим</p>
          <p class="text text_center">Қул һуаллааһу ахаді (1) Аллаһус-сомәді (2) Ләм йәлид уә ләм йууләді (3), уә ләм йәкул-ләһуу куфууән ахаді (4)</p>

          <p class="text">3. После намаза паломнику следует попить воду замзам.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.info {
  &:not(:last-child) {
    padding-bottom: 24px;
  }
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &_center {
      text-align: center;
    }
  }
}
</style>
