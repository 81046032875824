<script setup>
</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <div></div>
        <h2 class="title">Са’й</h2>
        <router-link to="/secondSai">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>

      <div class="content">
        <div class="steps"></div>
        <div class="info">
         <p class="text">
           <span class="text_orange">1. Сафа.</span>
           После окончания тавафа паломник направляется к сафе для выполнения семи хождений между холмами. Приближаясь к сафе, он произносит: «я начинаю с того, с чего начал аллах» и цитирует аят:
         </p>
          <p class="text text_arab">
            إِنَّ ٱلصَّفَا وَٱلْمَرْوَةَ مِن شَعَآئِرِ ٱللَّهِۖ فَمَنْ حَجَّ ٱلْبَيْتَ <br>أَوِ ٱعْتَمَرَ فَلَا جُنَاحَ عَلَيْهِ أَن يَطَّوَّفَ بِهِمَاۚ وَمَن تَطَوَّعَ <br>خَيْرًا فَإِنَّ ٱللَّهَ شَاكِرٌ عَلِيمٌ
          </p>
          <p class="text text-center">«Иннәс-сафа уаль-маруата мин шаъайи- риллях. Фаман хаджалл-байта ауиътама- ра фаля жунаха ъалайхи аййаттаууафа бихима. Уа ман татаууаъа хайран фа- инналлаха шакирун ъалим».</p>
          <p class="text text_orange text-center">Затем он поднимается на сафу, становится на ней и направляется лицом к каабе, трижды восхваляет аллаха (альхамду лил-лях) и возвеличивает его (аллаху акбар), и подняв руки, делает ду’а (взывает):</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.steps {
  margin: 0 auto;
  background: url("../../assets/img/sai.svg") center/cover no-repeat;
  width: 100%;
  max-width: 307px;
  height: 185px;
}
.info {
  padding-block: 24px;
  .text {
    &:not(:first-child) {
      padding-top: 16px;
    }
    &-center{
      text-align: center;
    }
  }
}
</style>
