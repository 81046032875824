<script setup>


</script>

<template>
  <section class="background">
    <div class="container">
      <div class="header_block">
        <router-link to="/mikat">
          <img src="@/assets/img/icons/prev.svg">
        </router-link>
        <h2 class="title">Микаты</h2>
        <router-link to="/thirdMikat">
          <img src="@/assets/img/icons/next.svg">
        </router-link>
      </div>
      <div class="content">
        <div class="info">
          <p class="text"><span class="text__selected">4. Ялямлям يلملم</span> – микат жителей Йемена и паломников,
            прибывающих с этого направ- ления. Сейчас это место
            называется «ас-Саъдия» и находится в 92 км от
            Благосло- венной Мекки.</p>
          <p class="text"><span class="text__selected">5. Зату ъирк ذات عرق</span> – микат жителей Ирака и
            паломников, прибывающих с этого направ- ления. Он
            расположен в 94 км от Благосло- венной Мекки.</p>
        </div>

        <div class="steps">
          <div class="container">
            <i class="text_green">
              В хадисе передают, что пророк определил местом
              вступления в ихрам для жителей медины Зуль-Хулейфу,
              для приезжающих из Сирии – аль-Джухфу, для
              прибывающих из неджда – Карн аль- Маназиль, а для
              жителей йемена – Ялям- лям. Эти места предназначены
              для них, а также для всех, кто желает совершить хадж
              или малое паломничество, но прибывает к
              вышепе- речисленным местам не из упомянутых
              областей. а что касается тех, кто прожи- вает вблизи
              мекки, то их паломничество начнется там, откуда они
              отправятся в путь, и поэтому жители мекки должны
              вступать в ихрам в самой Мекке.</i>
            <p class="text__source">(Аль-Бухари и Муслим)</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .info .text:first-child {
    padding-bottom: 16px;
  }
  .steps {
    padding-block: 16px;
  }

</style>
